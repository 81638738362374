import React from 'react';
import moment from 'moment';

// helpers =>
function convertISOFormatting(date: string) {
  return `${moment(date).format('MMMM DD, YYYY H:mm')}*`;
}
// helpers <=

const ConstructPolicyText = ({
  type,
  date_start,
  date_end,
  chargePrice,
  currency_code,
  policyT,
}: {
  type: 'free' | 'half' | 'full' | 'non-refund' | string;
  date_start?: string;
  date_end?: string;
  chargePrice?: string;
  currency_code?: string;
  policyT: any;
}) => {
    // const { policy: t } = useSelector(
    //     (data: any) => data.config.translations.roomPricing
    // );

    if (type === 'free' && date_end) {
    return (
      <p className="policy-item free">
          {policyT.freeCancallationUntil}
        <span className="bold">{convertISOFormatting(date_end)}</span>
      </p>
    );
  }
  if (type === 'half' && date_end && chargePrice) {
    return (
      <p className="policy-item half">
          {policyT?.cancallationBeCharged}
        <span className="bold">
          {chargePrice}{String.fromCharCode(160)}{currency_code}
        </span>
          { date_start ? <>
                  {policyT?.ifYouCancelBetween}
                <span className="bold">{convertISOFormatting(date_start)}</span> {policyT?.till}
                <span className="bold">{convertISOFormatting(date_end)}</span>
          </> :
              <>
                  {policyT?.ifYouCancelTill}
                  <span className="bold">{convertISOFormatting(date_end)}</span>
              </>
          }
      </p>
    );
  }
  if (type === 'full' && date_start) {
    return (
      <p className="policy-item full">
          {policyT?.ifYouCancelModifiedBetween}
        <span className="bold">{convertISOFormatting(date_start)}</span>,
          {policyT?.cancelFullPrice}
      </p>
    );
  }
  if (type === 'non-refund' && !date_start) {
    return (
      <p className="policy-item full">
          {policyT?.rateNonRefundable}
      </p>
    );
  }
  return <></>;
};

export default ConstructPolicyText;
