import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SiteConfig } from '../interfaces';
import { updateLang, updateTranslations } from '../store/config';
import { RootState } from '../store/store';
import translations from '../translations/translationsHe';
import translationsEn from '../translations/translationsEn';
import translationsUk from '../translations/translationsUk';
import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from '../utils/endpoints';
import mergeDeep from '@skygroup/shared/utils/mergeDeep';
import moment from "moment/moment";

interface Props {
  siteConfig?: SiteConfig;
}

export default function useSiteLang({ siteConfig }: Props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLang } = useSelector((data: RootState) => data.config);
  const [isTranLoaded, setIsTranLoaded] = useState(false);

  const [selectedLang, setSelectedLang] = useState<string | null>(null);
  const [direction, setDirection] = useState<'ltr' | 'rtl'>("ltr");

  const getTranslations = useCallback(async () => {
    // make sure not proceed if we don't have both (selectedLang & siteConfig) - they loaded in async
    if (!selectedLang || !siteConfig) {
      return;
    }
    console.log("loading translation....", selectedLang);
    // const response = await apiClient.get(
    //   `${endpoints.APP.translations}?lang=en&app=hotel`
    // );
    
    // TEMPORARY BUG FIX: NEEDS DEBUGGING !!!!
    // await import(`moment/locale/en`) ----> cannot find ./en, thus import en-israel
    const test = selectedLang === "en_US" ? 'en-il' :  selectedLang.substring(0, 2);
    const localeModule = await import(`moment/locale/${test}`);
    // TEMPORARY BUG FIX: NEEDS DEBUGGING !!!!
    moment.locale(selectedLang);
    
    // const translations = response.data.data.translation;
    dispatch(updateLang(selectedLang));
    if (selectedLang == 'en_US') {
      dispatch(updateTranslations(mergeDeep(translations.data.translation, translationsEn.data.translation)));
    } else if (selectedLang == 'uk_UA') {
      dispatch(updateTranslations( mergeDeep(translations.data.translation, translationsUk.data.translation)));
    } else {
      dispatch(updateTranslations(translations.data.translation));
    }
    const matchedConfigLang = siteConfig?.languages.find(
      (item) => item?.code === selectedLang
    );
    if (matchedConfigLang?.dir) {
      setDirection(matchedConfigLang.dir);
    }
    setIsTranLoaded(true);
  }, [dispatch, selectedLang, siteConfig]);

  useEffect(() => {
    // set default val for selectedLang once config is loaded
    const isMatched = siteConfig?.languages.find((item) => item.code === searchParams.get('lang'));
    if (searchParams.get('lang') && isMatched?.code) {
      console.log("Set from url param")
      setSelectedLang(searchParams.get('lang'));
    } else if (localStorage.getItem('lang')) {
      console.log("Set from localstorage", localStorage.getItem('lang'))
      setSelectedLang(localStorage.getItem('lang'));
    } else if (siteConfig?.defaultLang) {
      console.log("Set from default", siteConfig?.defaultLang)
      setSelectedLang(siteConfig.defaultLang);
    }
  }, [searchParams, siteConfig]);

  useEffect(() => {
    // user manually updated url
    const langParam = searchParams.get('lang');
    const isMatched = siteConfig?.languages.find((item) => item.code === langParam);
    if (langParam === null || !isMatched) {
      return;
    }
    localStorage.setItem('lang', langParam);
    setSelectedLang(langParam);
  }, [searchParams, siteConfig?.languages]);

  // init trans and config
  useEffect(() => {
    getTranslations();
  }, [selectedLang, siteConfig]);

  useEffect(() => {
    // currLang updated from lang dropdown
    if (currentLang) {
      setSelectedLang(currentLang);
    }
  }, [currentLang]);

  return { isTranLoaded, direction };
}
